<template>
  <div>
    <!-- 软著批量下载  -->
    <Header back="返回" title="软著列表" index="首页" titleOne="科创模块" titleTwo="知识产权" beforeTitle="软著列表" />
    <div class="content">
      <h1>附件批量下载</h1>
      <div class="topUsers">
        <div>
          <el-input
            clearable
            v-model="softParams.company_name"
            placeholder="请输入企业名称"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-input
            clearable
            v-model="softParams.soft_name"
            placeholder="请输入软著名称/登记号"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-select
            clearable
            v-model="softParams.status"
            placeholder="请选择授权状态"
            @change="changePatentDays"
          >
            <el-option label="授权" value="1"></el-option>
            <el-option label="未授权" value="2"></el-option>
          </el-select>
          <el-button class="search" @click="handleSearch()">查询</el-button>
        </div>
        <el-button @click="download" type="primary" class="download">一键下载</el-button>
      </div>

      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="softList.data"
        style="width: 100%"
      >
        <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.company_name ? scope.row.company_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="soft_name" label="软著名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.soft_name ? scope.row.soft_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="register_number" label="登记号" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.register_number ? scope.row.register_number : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="授权类型" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">授权</span>
            <span v-if="scope.row.status == 2">未授权</span>
          </template>
        </el-table-column>
        <el-table-column prop="patent_name" label="操作" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-button class="edit" size="mini" @click="downloadFile(scope.row.url)">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-if="softList.total > 0">
        <el-pagination
          @size-change="softSizeChange"
          @current-change="softCurrentChange"
          :current-page="softParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="softParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="softList.total"
        ></el-pagination>
      </div>
    </div>

    <el-dialog :visible.sync="centerDialogVisible" width="20%" center top="15%">
      <span>由于数据庞大，请查询或筛选后一键下载！</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import site from '../../components/global.vue'
export default {
  data () {
    return {
      centerDialogVisible: false,
      loadUrl: site.userSite,
      loading: false,
      softParams: {
        page: 1,
        limit: 10,
      },
      softList: {},
    }
  },
  created () {
    this.getList()
  },
  methods: {
    softSizeChange (a) {
      this.getList(this.softParams.limit = a)
    },
    softCurrentChange (a) {
      this.getList(this.softParams.page = a)
    },
    getList () {
      this.loading = true
      this.axios.get('/api/soft/download_file_list', { params: { ...this.softParams } }).then((res) => {
        this.softList = res.data
        this.loading = false
      })
    },
    changePatentDays () {
      this.getList()
    },
    handleSearch () {
      this.getList()
    },
    downloadFile (url) {
      window.open(this.loadUrl + url)
    },
    download () {
      console.log(this.softParams)
      if (this.softParams.company_name || this.softParams.soft_name || this.softParams.status) {
        this.axios.get('/api/soft/download_file', { params: { ...this.softParams } }).then((a) => {
          console.log(a)
          window.open(this.loadUrl + a.data.url)
        })
      } else {
        this.centerDialogVisible = true
      }
    },
  },
}
</script>

<style scoped>
div/deep/.download {
  background: #edeef4;
  border: #edeef4;
  color: #909399;
}
div/deep/.el-dialog__body {
  margin: 30px 0;
}
</style>